import { Alert, Button, Divider, Sheet, Typography } from "@mui/joy";
import { Box, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { InternalPosition } from "entities/internal/InternalPosition";
import { MusicianStack } from "features/chair/v2/position";
import { usePreviewChanges } from "features/projects/ProjectMissionControl/LayoutUtils";
import { ProjectPiecesSelect } from "features/projects/ProjectMissionControl/projectPiecesSelect";
import { useMissionControlSelectAll } from "features/projects/ProjectMissionControl/utils";
import { WorkSessionsSelect } from "features/projects/ProjectMissionControl/workSessionsSelect";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import {
  layoutUtilsSelector,
  reorderInternalSectionSelector,
  restoreSelection,
  selectionSelector,
  setReorderInternalSection,
} from "reducers/v2/missionControl";
import { useCompanySettings } from "redux/company/companyHooks";
import { useProjectPieces } from "redux/projectPiece/projectPieceHooks";
import { useChairsReorderMutation } from "redux/rhapsodyChair/rhapsodyChairEndpoints";
import { useSections } from "redux/section/sectionHooks";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
});

/**
 *
 * @returns {ReactElement} ReorderMusicianInSection page
 */
export function ReorderMusicianInSection() {
  const internalSection = useSelector(reorderInternalSectionSelector);
  const open = useSelector(formOpenSelector("reorderInSection"));
  const { sectionsMap } = useSections();
  const [list, setList] = useState<InternalPosition[]>([]);
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectPieces } = useProjectPieces(projectID);
  const layoutUtils = useSelector(layoutUtilsSelector);
  const { musiciansMap } = layoutUtils;
  const { selectAllWorkSessions, selectAllProjectPieces } =
    useMissionControlSelectAll();
  const selection = useSelector(selectionSelector);
  const dispatch = useDispatch();
  const [chairsReorder] = useChairsReorderMutation();

  useEffect(() => {
    setList(internalSection.getPositions(internalSection.enabledPositionIDs));
    console.log(
      "Set List",
      internalSection.getPositions(internalSection.enabledPositionIDs)
    );
  }, [internalSection]);

  const onClose = () => {
    dispatch(setReorderInternalSection(null));
    dispatch(restoreSelection());
    dispatch(setFormOpen({ isOpen: false, formID: "reorderInSection" }));
  };

  async function onDragEnd(result) {
    const newItems = [...list];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setList(newItems);

    let workSessionIDs;
    if (internalSection.forWorkSessionID) {
      workSessionIDs = [internalSection.forWorkSessionID];
    } else if (!selection.allWorkSessionsSelected) {
      workSessionIDs = selection.workSessionIDs;
    }
    await chairsReorder({
      id: 0,
      body: {
        projectID: projectID,
        sectionID: internalSection.sectionID,
        position: result.destination.index + 1,
        chairIDs: newItems[result.destination.index].visibleChairIDs,
        workSessionIDs,
        preview: false,
      },
    });
  }

  const enabledPositions = internalSection.getPositions(
    internalSection.enabledPositionIDs
  );

  const otherPositions = internalSection.getPositions(
    internalSection.otherPositionIDs
  );

  const exceptionChairs = selection.userIsAboutToCreateWorkSessionChairs(
    enabledPositions,
    internalSection.enabledPositionIDs,
    layoutUtils
  );

  const workSessionCountSelectionIsAbout =
    selection.workSessionCountSelectionIsAbout(
      enabledPositions,
      internalSection.enabledPositionIDs,
      layoutUtils
    ).length;

  const exceptionWarning = (
    <Box
      sx={{
        position: "absolute",
        top: -26,
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Alert
        variant="solid"
        color="danger"
        size="sm"
        sx={{
          p: 0.5,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        <Typography level="body3" sx={{ color: "white" }}>
          You are about to make a change that will only affect{" "}
          {workSessionCountSelectionIsAbout} Work Session
          {workSessionCountSelectionIsAbout > 1 ? "s" : ""}
        </Typography>
      </Alert>
    </Box>
  );

  const header = (
    <DialogTitle>
      <Box>
        <Typography level="h6">
          Reorder {sectionsMap[internalSection.sectionID]?.name}
        </Typography>
      </Box>
    </DialogTitle>
  );

  const ITEM_HEIGHT = 50;

  const save = () => {
    onClose();
  };

  return (
    <DialogClose
      classes={{
        paper: "dialog-paper",
      }}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      {exceptionChairs ? exceptionWarning : []}
      {header}
      <Divider />
      <DialogContent sx={{ display: "flex", p: 0 }}>
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Box sx={{ position: "relative", maxWidth: 450, width: "100vw" }}>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {internalSection.positions.map((p) => (
                <Box
                  sx={{
                    height: ITEM_HEIGHT,
                    display: "flex",
                    alignItems: "center",
                  }}
                  key={p.id}
                >
                  <Typography level="body2">{p.order}.</Typography>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                width: "100%",
                maxWidth: "calc(100% - 48px)",
                display: "flex",
                gap: 1,
                flexDirection: "column",
              }}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={"Box"}>
                  {(provided) => {
                    return (
                      <Box
                        ref={provided.innerRef}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        {list.map((p, i) => {
                          const visibleMusicianIDs = p.visibleMusicianIDs;
                          const visibleMusicians = visibleMusicianIDs.reduce(
                            (a, v) => {
                              if (musiciansMap[v]) a.push(musiciansMap[v]);
                              return a;
                            },
                            []
                          );

                          return (
                            <Draggable key={p.id} draggableId={p.id} index={i}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <Sheet
                                    variant="soft"
                                    sx={{
                                      height: ITEM_HEIGHT,
                                      ml: 4,
                                      mb: 0.5,
                                      mt: 0.5,
                                      display: "flex",
                                      gap: 0.5,
                                      borderRadius: "8px",
                                      p: 1,
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: 0.5,
                                        alignItems: "center",
                                      }}
                                    >
                                      {visibleMusicianIDs.length ? (
                                        <MusicianStack
                                          noTooltip
                                          musicians={visibleMusicians}
                                          position={p}
                                          utils={layoutUtils}
                                        />
                                      ) : (
                                        <Typography
                                          sx={{ pl: 1 }}
                                          startDecorator={
                                            <i className="fa-solid fa-chair"></i>
                                          }
                                          level="body2"
                                        >
                                          Empty Chair
                                        </Typography>
                                      )}
                                    </Box>
                                    <i
                                      style={{ opacity: 0.5, marginRight: 8 }}
                                      className="fa-solid fa-grip-dots-vertical"
                                    ></i>
                                  </Sheet>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </Box>
                    );
                  }}
                </Droppable>
              </DragDropContext>
              {otherPositions.map((p) => (
                <Sheet
                  key={p.id}
                  variant="soft"
                  sx={{
                    height: ITEM_HEIGHT,
                    ml: 4,
                    display: "flex",
                    gap: 0.5,
                    borderRadius: "8px",
                    p: 1,
                    alignItems: "center",
                    justifyContent: "space-between",
                    opacity: 0.5,
                    backdropFilter: "blur(10px)",
                  }}
                ></Sheet>
              ))}
            </Box>
          </Box>
        </Box>
        <Divider orientation="vertical" />
        <Box sx={{ width: 320 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pl: 1,
              pr: 1,
            }}
          >
            <Typography sx={{ height: 28 }}>Work Sessions</Typography>
            {layoutUtils.workSessions.length > 1 ? (
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={selectAllWorkSessions}
                level="body3"
              >
                Select All
              </Typography>
            ) : (
              []
            )}
          </Box>
          <Divider />
          <WorkSessionsSelect hideEdit />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pl: 1,
              pr: 1,
            }}
          >
            <Typography sx={{ height: 28 }}>Project Pieces</Typography>
            {projectPieces.length > 1 ? (
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={selectAllProjectPieces}
                level="body3"
              >
                Select All
              </Typography>
            ) : (
              []
            )}
          </Box>
          <Divider />
          <ProjectPiecesSelect hideMenu />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} variant="soft" color="neutral">
          Close
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
